import {
  FitnessPlan,
  MealPlan,
} from './@core/interfaces/common/CalorieFriend/plan';
import {
  FitnessPlanDay,
  MealPlanDay,
} from './@core/interfaces/common/CalorieFriend/plan-day';
import { Meal, MealItem } from './@core/interfaces/common/CalorieFriend/meal';
import { FoodItemDataSource } from './@core/interfaces/common/CalorieFriend/food';
import {
  BlockType,
  FitnessBlock,
  FitnessItem,
  SetsBased,
  SettingDetailRow,
} from './@core/interfaces/common/CalorieFriend/fitnessItem';

export function CopyMealItem(
  sourceItem: MealItem,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): MealItem {
  const copyMealItem: MealItem = {
    id: copyToCreate ? 0 : sourceItem.id,
    mealID: sourceItem.mealID,
    foodId: sourceItem.foodId,
    quantity: sourceItem.quantity,
    selectedServingSizeIndex: sourceItem.selectedServingSizeIndex,
    selectedServingSizeEntered: sourceItem.selectedServingSizeEntered,
    isCustomFood: sourceItem.isCustomFood,
    order: sourceItem.order,
    isCustomServingSize: sourceItem.isCustomServingSize,
    customServingSize: sourceItem.customServingSize,
    conversionFactorsValue: sourceItem.conversionFactorsValue,
    isQuickAdd: sourceItem.isQuickAdd,

    formattedUrlDescription: forSaving
      ? null
      : sourceItem.formattedUrlDescription,
    urlPath: forSaving ? null : sourceItem.urlPath,
    urlPathWithSpaces: forSaving ? null : sourceItem.urlPathWithSpaces,
    description: forSaving ? null : sourceItem.description,
    searchKeywords: forSaving ? null : sourceItem.searchKeywords,
    photoName: forSaving ? null : sourceItem.photoName,
    photoCount: forSaving ? null : sourceItem.photoCount,
    photoIncrementInGram: forSaving ? null : sourceItem.photoIncrementInGram,
    defaultPhotoServingSize: forSaving
      ? null
      : sourceItem.defaultPhotoServingSize,
    friendlyName: forSaving ? null : sourceItem.friendlyName,
    unit: forSaving ? null : sourceItem.unit,
    calorie: forSaving ? null : sourceItem.calorie,
    calorieFromFat: forSaving ? null : sourceItem.calorieFromFat,
    fat: forSaving ? null : sourceItem.fat,
    saturatedFat: forSaving ? null : sourceItem.saturatedFat,
    transFat: forSaving ? null : sourceItem.transFat,
    cholesterol: forSaving ? null : sourceItem.cholesterol,
    sodium: forSaving ? null : sourceItem.sodium,
    carb: forSaving ? null : sourceItem.carb,
    fiber: forSaving ? null : sourceItem.fiber,
    sugar: forSaving ? null : sourceItem.sugar,
    protein: forSaving ? null : sourceItem.protein,
    netCarb: forSaving ? null : sourceItem.netCarb,
    imageURL: forSaving ? null : sourceItem.imageURL,
    selected: forSaving ? null : sourceItem.selected,
    passed: forSaving ? null : sourceItem.passed,
    units: forSaving ? null : sourceItem.units,
    source: forSaving ? null : sourceItem.source,
    useFrontImageAsDefault: forSaving
      ? null
      : sourceItem.useFrontImageAsDefault,
    foodCategories: forSaving ? null : sourceItem.foodCategories,
    similiarFoods: null,
    searchTerms: forSaving ? null : sourceItem.searchTerms,
    Current: null,
    Update: null,
    foodItemDataSource: forSaving ? null : FoodItemDataSource.Figwee,
    foodItem: null,
    totalGrams: forSaving ? null : sourceItem.totalGrams,

  };

  return copyMealItem;
}

export function CopyMeal(
  source: Meal,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): Meal {
  const copyMeal: Meal = {
    id: copyToCreate ? 0 : source.id,
    dayID: source.dayID,
    name: source.name,
    time: source.time,
    items: null,
  };

  if (source?.items?.length > 0) {
    copyMeal.items = Array<MealItem>();

    for (const item of source.items) {
      copyMeal.items.push(CopyMealItem(item, copyToCreate, forSaving));
    }
  }

  return copyMeal;
}

export function CopyDay(
  source: MealPlanDay,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): MealPlanDay {
  const copyDay: MealPlanDay = {
    id: copyToCreate ? 0 : source.id,
    planID: source.planID,
    meals: null,
    name: source.name,
    notes: source.notes,
    dayOfWeek: source.dayOfWeek == null ? [] : [...source.dayOfWeek],
    createMeal: source.createMeal,
    order: source.order,
  };

  if (
    source.meals !== null &&
    source.meals !== undefined &&
    source.meals.length > 0
  ) {
    copyDay.meals = new Array<Meal>();

    for (const meal of source.meals) {
      copyDay.meals.push(CopyMeal(meal, copyToCreate, forSaving));
    }
  }

  return copyDay;
}

export function CopyFitnessItem(
  sourceItem: FitnessItem,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): FitnessItem {
  const copyFitnessItem: FitnessItem = {
    id: copyToCreate ? 0 : sourceItem.id,
    fitnessBlockID: sourceItem.fitnessBlockID,
    exerciceId: sourceItem.exerciceId,
    sets: sourceItem.sets,
    setsBased: sourceItem.setsBased,
    defaultRepsOrTime: sourceItem.defaultRepsOrTime,
    defaultTempo: sourceItem.defaultTempo,
    defaultRest: sourceItem.defaultRest,
    defaultWeight: sourceItem.defaultWeight,
    weightType: sourceItem.weightType,
    oneRepMax: sourceItem.oneRepMax,
    restBeforeNextExercise: sourceItem.restBeforeNextExercise,
    setSettings: sourceItem.setSettings,
    isCustom: sourceItem.isCustom,
    order: sourceItem.order,
    useFemaleVersion: sourceItem.useFemaleVersion,
    showAdvancedMode: sourceItem.showAdvancedMode,
    instructions: sourceItem.instructions,
    isQuickAdd: sourceItem.isQuickAdd,
    customName: sourceItem.customName,


    Update: null,

    searchWeight: forSaving ? null : sourceItem.searchWeight,
    name: forSaving ? null : sourceItem.name,
    videoName: forSaving ? null : sourceItem.videoName,
    imageName: forSaving ? null : sourceItem.imageName,
    imageNameFemale: forSaving ? null : sourceItem.imageNameFemale,

    equipments: forSaving ? null : sourceItem.equipments,
    bodyParts: forSaving ? null : sourceItem.bodyParts,
    muscleTarget: forSaving ? null : sourceItem.muscleTarget,
    muscleSynergist: forSaving ? null : sourceItem.muscleSynergist,
    searchTerms: forSaving ? null : sourceItem.searchTerms,

    imageUrl: forSaving ? null : sourceItem.imageUrl,
    imageMediumUrl: forSaving ? null : sourceItem.imageMediumUrl,
    videoUrl: forSaving ? null : sourceItem.videoUrl,
    femaleImageUrl: sourceItem.femaleImageUrl,
    femaleImageMediumUrl: forSaving ? null : sourceItem.femaleImageMediumUrl,
    femaleVideoUrl: forSaving ? null : sourceItem.femaleVideoUrl,
    youtubeUrl: forSaving ? null : sourceItem.youtubeUrl,
    exerciseVideoThumbnailUrl: sourceItem.exerciseVideoThumbnailUrl,

    selected: false,
  };

  return copyFitnessItem;
}

export function CopyFitnessBlock(
  source: FitnessBlock,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): FitnessBlock {
  const copyFitnessBlock: FitnessBlock = {
    id: copyToCreate ? 0 : source.id,
    dayID: source.dayID,
    name: source.name,
    blockType: source.blockType,
    repetitionCount: source.repetitionCount,
    circuitRest: source.circuitRest,
    restEnd: source.restEnd,
    items: null,
    order: source.order,
  };

  if (source?.items?.length > 0) {
    copyFitnessBlock.items = Array<FitnessItem>();

    for (const item of source.items) {
      copyFitnessBlock.items.push(
        CopyFitnessItem(item, copyToCreate, forSaving)
      );
    }
  }

  return copyFitnessBlock;
}

export function CopyFitnessDay(
  source: FitnessPlanDay,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): FitnessPlanDay {
  const copyDay: FitnessPlanDay = {
    id: copyToCreate ? 0 : source.id,
    planID: source.planID,
    blocks: null,
    name: source.name,
    notes: source.notes,
    dayOfWeek: source.dayOfWeek == null ? [] : [...source.dayOfWeek],
    createMeal: source.createMeal,
    order: source.order,
  };

  if (
    source.blocks !== null &&
    source.blocks !== undefined &&
    source.blocks.length > 0
  ) {
    copyDay.blocks = new Array<FitnessBlock>();

    for (const block of source.blocks) {
      copyDay.blocks.push(CopyFitnessBlock(block, copyToCreate, forSaving));
    }
  }

  return copyDay;
}

// for now the
export function CopyPlan(
  source: MealPlan,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): MealPlan {
  const copyPlan: MealPlan = {
    id: source.id,
    days: null,
    name: source.name,
    patientID: source.patientID,
    createdDate: source.createdDate,
    description: source.description,
    notes: source.notes,
    zOrder: source.zOrder,
    active: source.active,
    duration: source.duration,
  };

  if (source.days?.length > 0) {
    copyPlan.days = new Array<MealPlanDay>();

    for (const day of source.days)
      copyPlan.days.push(CopyDay(day, copyToCreate, forSaving));
  }

  return copyPlan;
}

// for now the
export function CopyFitnessPlan(
  source: FitnessPlan,
  copyToCreate: boolean = false,
  forSaving: boolean = false
): FitnessPlan {
  const copyPlan: FitnessPlan = {
    id: source.id,
    days: null,
    name: source.name,
    patientID: source.patientID,
    createdDate: source.createdDate,
    description: source.description,
    notes: source.notes,
    zOrder: source.zOrder,
    active: source.active,
    duration: source.duration,
  };

  if (source.days !== null && source !== undefined && source.days.length > 0) {
    copyPlan.days = new Array<FitnessPlanDay>();

    for (const day of source.days) {
      copyPlan.days.push(CopyFitnessDay(day, copyToCreate, forSaving));
    }
  }

  return copyPlan;
}

// calculating the metrics for meal item
export function CalculateMetrics(item: MealItem) {
  const result: any = {
    MealCals: 0,
    MealProtein: 0,
    MealCarbs: 0,
    MealNetCarbs: 0,
    MealFats: 0,
  };

  if (item.Current == null) {
    if ((item as any).value) {
      (item as any).Current = (item as any).value.Current;
    }
  }

  if (
    item.Current !== null &&
    item.Current !== undefined &&
    item.Current.nutrientsDataItem !== null &&
    item.Current.nutrientsDataItem !== undefined
  ) {
    result.MealCals = item.Current.nutrientsDataItem.calorie * item.quantity;
    result.MealProtein = item.Current.nutrientsDataItem.protein * item.quantity;
    result.MealCarbs = item.Current.nutrientsDataItem.carb * item.quantity;
    result.MealNetCarbs =
      item.Current.nutrientsDataItem.carb * item.quantity -
      item.Current.nutrientsDataItem.fiber * item.quantity;
    result.MealFats = item.Current.nutrientsDataItem.fat * item.quantity;
  } else {
    if (item) {
      result.MealCals = item.calorie * item.quantity;
      result.MealProtein =
        item.protein * item.quantity;
      result.MealCarbs = item.carb * item.quantity;
      result.MealNetCarbs =
        item.carb * item.quantity -
        item.fiber * item.quantity;
      result.MealFats = item.fat * item.quantity;
    }
  }

  return result;
}

// calculating the summary for fitness item
export function CalculateSummary(
  item: FitnessItem,
  isCircuit: boolean = false
) {
  const result: any = { Exercises: 0, TotalReps: 0, TotalSeconds: 0 };

  let detailSettings = [];
  try {
    detailSettings = JSON.parse(item.setSettings) as SettingDetailRow[];
  } catch (e) {}

  result.Exercises = 1;

  if (isCircuit) {
    console.log(item.setsBased);
    if (item.setsBased === SetsBased.RepsBased) {
      result.TotalSeconds += item.defaultRepsOrTime * 5;
      result.TotalReps += item.defaultRepsOrTime;
    } else {
      result.TotalSeconds += item.defaultRepsOrTime;
      result.TotalReps += 1;
    }
  } else {
    for (const set of detailSettings) {
      if (item.setsBased === SetsBased.RepsBased) {
        result.TotalSeconds += +set.RepsOrTime * 5;
        result.TotalReps += +set.RepsOrTime;
      } else {
        result.TotalSeconds += +(set.RepsOrTime * 60);
        result.TotalReps += 1;
      }
      result.TotalSeconds += +set.Rest;
    }
  }
  result.TotalSeconds += item.defaultRest;

  return result;
}

// calculating the summary for fitness item
export function CalculateBlockSummary(block: FitnessBlock) {
  const result: any = { Exercises: 0, TotalReps: 0, TotalSeconds: 0 };

  if (block) {
    if (block.items && block.items.length > 0) {
      for (let i = 0; i < block.items.length; i++) {
        const item = block.items[i];
        const itemMetrics = CalculateSummary(
          item,
          block.blockType === BlockType.Circuit
        );
        result.Exercises += itemMetrics.Exercises;
        result.TotalReps += itemMetrics.TotalReps;
        result.TotalSeconds += itemMetrics.TotalSeconds;

        if (
          block.blockType === BlockType.Circuit &&
          i !== block.items.length - 1
        ) {
          result.TotalSeconds += block.circuitRest;
        }
      }
    }
  }

  if (block.blockType === BlockType.Circuit) {
    result.Exercises = result.Exercises * block.repetitionCount;
    result.TotalReps = result.TotalReps * block.repetitionCount;
    result.TotalSeconds = result.TotalSeconds * block.repetitionCount;
  }

  return result;
}
