import { Injectable } from "@angular/core";
import { AppSettings, AppSettingsData } from "app/@core/interfaces/common/appSettings";
import { AppSettingsApi } from "../api/appSettings.api";
import { Observable } from "rxjs";

@Injectable()
export class AppSettingsService extends AppSettingsData {
    runBuild(userId?: number): Observable<any> {
        return this.api.runBuild(userId);
    }
    
    constructor(private api: AppSettingsApi) {
        super();
    }

    get(id: number): Observable<AppSettings> {
        return this.api.get(id);
    }
    
    update(user: AppSettings): Observable<AppSettings> {
        return this.api.update(user);
    }

    sendNotifications(deviceToken: any): Observable<any> {
        return this.api.sendNotifications(deviceToken);
    }
    
}