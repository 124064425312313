<nb-card class="nb_card_pop_up full_pop_up meal_item mobilePopup">

  <nb-card-header>
    <div class="title">
      <ng-container i18n="@@AddNewItemToMeal">Add New Item To Meal</ng-container>
    </div>
    <div class="actions">
      <button nbButton ghost  tabindex="-1">
        <nb-icon icon="close-outline" (click)="Accept()" style="z-index: 1000;"></nb-icon>
      </button>
    </div>
  </nb-card-header>

  <nb-card-body>

    <!-- <div style="margin-bottom: 10px;">
    <input style="width:300px;" nbInput fullWidth shape="semi-round" i18n-placeholder="@@Filter" placeholder="Filter"
      (input)="FilterChange($event.target.value)" (keyup.enter)="ApplyFilterChange()"
      [nbAutocomplete]="autoControl" />
    <nb-autocomplete #autoControl>
      <nb-option *ngFor="let option of filteredFoods" [value]="option">
        {{ option }}
      </nb-option>
    </nb-autocomplete>
  </div> -->

    <div style="margin-bottom: 10px;" class="row meal_pop_up_header">
      <div class="col-lg-12">
        <nb-form-field>
                  <input #filter style="width:100%" nbInput fullWidth shape="semi-round" i18n-placeholder="@@Filter"
          placeholder="Search food..." (input)="FilterChange($event.target.value)"
          (keyup.enter)="ApplyFilterChange()" />
          <button *ngIf="CurrentFilter.length > 0" nbSuffix nbButton ghost (click)="onClear()">
            <nb-icon icon="close" pack="eva" attr.aria-label="Remove"></nb-icon>
          </button>
        </nb-form-field>

        <br />
        <a i18n="@@NotFindingWhatYouWant" (click)="onNewCustomMealItem()"
          style="cursor:pointer; font-size:12px; color:blue; text-decoration:underline; font-weight:normal;">Not finding
          what you want? Create a new food item.</a>
      </div>
    </div>

    <nb-tabset #tabset class="food_array">
      <nb-tab #tabItemWithImage class="food-tab" [tabTitle]="ImageListCountHTML" responsive>
        <virtual-scroller *ngIf="tabItemWithImage.activeValue" #ItemsWithImageScroll [items]="ItemsWithImage" style="height: 100%; min-height: 100px;"
          [bufferAmount]="5" [enableUnequalChildrenSizes]="true" [checkResizeInterval]="0"
          (vsEnd)="FetchMoreItemWithImages($event)">
          <div *ngFor="let item of ItemsWithImageScroll.viewPortItems">
            <app-serving-size-selection [Patient]="Patient" [value]="item.value"></app-serving-size-selection>
          </div>
        </virtual-scroller>
      </nb-tab>

      <nb-tab #tabCustomItem class="food-tab" [tabTitle]="CustomFoodListCountHTML" responsive>
        <virtual-scroller *ngIf="tabCustomItem.activeValue" #CustomItemsScroll [items]="CustomItems" style="height: 100%; min-height: 100px;"
          [bufferAmount]="5" [enableUnequalChildrenSizes]="true" [checkResizeInterval]="0"
          (vsEnd)="FetchMoreCustomItem($event)">
          <div *ngFor="let item of CustomItemsScroll.viewPortItems">
            <app-serving-size-selection [Patient]="Patient" [value]="item.value"></app-serving-size-selection>
          </div>
        </virtual-scroller>
      </nb-tab>

      <nb-tab #tabCustomPublicItem class="food-tab" [tabTitle]="CustomPublicFoodListCountHTML" responsive>

        <virtual-scroller *ngIf="tabCustomPublicItem.activeValue" #CustomPublicItemsScroll [items]="CustomPublicItems" style="height: 100%;" [bufferAmount]="5"
          [enableUnequalChildrenSizes]="true" [checkResizeInterval]="0" (vsEnd)="FetchMoreCustomPublicItem($event)">
          <div *ngFor="let item of CustomPublicItemsScroll.viewPortItems">
            <app-serving-size-selection [value]="item.value"></app-serving-size-selection>
          </div>
        </virtual-scroller>

        <div style="height: 200px;">
        </div>
      </nb-tab>

      <nb-tab #tabItemWithoutImage class="food-tab" [tabTitle]="NoImageListCountHTML" responsive>
        <virtual-scroller #ItemsWithNoImageScroll *ngIf="tabItemWithoutImage.activeValue" [items]="ItemsWithNoImage" style="height:100%;" [bufferAmount]="5"
          [enableUnequalChildrenSizes]="true" [checkResizeInterval]="0" (vsEnd)="FetchMoreItemWithNoImages($event)">
          <div *ngFor="let item of ItemsWithNoImageScroll.viewPortItems">
            <app-serving-size-selection [value]="item.value"></app-serving-size-selection>
          </div>
        </virtual-scroller>
      </nb-tab>

    </nb-tabset>

  </nb-card-body>

</nb-card>
